import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import 'summernote/dist/summernote-bs4.min.js'

export default class ComplianceApprovalTestsEdit {

  constructor() {}

  render() {
    const accountUrl = $('body').attr('data-account-url');

    // Search
    filterTypeahead('#search_portfolios_for_test', 'portfolios', '/' + accountUrl + '/portfolios/search?', 182, 'generic');
    filterTypeahead('#search_funds_for_test', 'funds', '/funds/search?', 133, 'generic');
    filterTypeahead('#search_approval_rules', 'approval_rules', '/' + accountUrl + '/compliance/approval_rules/search?', 132, 'generic');
    filterTypeahead('#search_test_followers', 'users', '/' + accountUrl + '/users/search?', 95, 'generic');

    // On selecting a portfolio
    $('#search_portfolios_for_test').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      $(this).typeahead('val','');
      $(this).blur();
      $('.modal-body').css('opacity', 0.2);
      Spinner($('.modal-content'));
      let testId = $(this).attr('data-test-id');
      let url = '/' + accountUrl + '/compliance/approval_tests/' + testId + '/approval_test_portfolios?';
      let params = {};
      params['portfolio_id'] = suggestion.value;
      $.ajax({
        type: 'POST',
        dataType: 'script',
        url: url + $.param(params)
      });
    });

    // On selecting a fund
    $('#search_funds_for_test').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      $(this).typeahead('val','');
      $(this).blur();
      $('.modal-body').css('opacity', 0.2);
      Spinner($('.modal-content'));
      let testId = $(this).attr('data-test-id');
      let url = '/' + accountUrl + '/compliance/approval_tests/' + testId + '/approval_test_funds?';
      let params = {};
      params['fund_id'] = suggestion.value;
      $.ajax({
        type: 'POST',
        dataType: 'script',
        url: url + $.param(params)
      });
    });

    // On selecting a rule
    $('#search_approval_rules').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      $(this).typeahead('val','');
      $(this).blur();
      $('.modal-body').css('opacity', 0.2);
      Spinner($('.modal-content'));
      let testId = $(this).attr('data-test-id');
      let url = '/' + accountUrl + '/compliance/approval_tests/' + testId + '/approval_test_rules?';
      let params = {};
      params['rule_id'] = suggestion.value;
      $.ajax({
        type: 'POST',
        dataType: 'script',
        url: url + $.param(params)
      });
    });

    // On selecting a follower
    $('#search_test_followers').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      $(this).typeahead('val','');
      $(this).blur();
      $('.modal-body').css('opacity', 0.2);
      Spinner($('.modal-content'));
      let testId = $(this).attr('data-test-id');
      let url = '/' + accountUrl + '/compliance/approval_tests/' + testId + '/approval_test_followers?';
      let params = {};
      params['user_id'] = suggestion.value;
      $.ajax({
        type: 'POST',
        dataType: 'script',
        url: url + $.param(params)
      });
    });

    // Remove test portfolio
    $('.remove-portfolio-from-test').click(function() {
      $('.modal-body').css('opacity', 0.2);
      Spinner($('.modal-content'));
      let row = $(this).closest('.test-portfolio-row');
      let sid = $(this).attr('data-test-portfolio-id');
      let testId = $(this).attr('data-test-id');
      let url = '/' + accountUrl + '/compliance/approval_tests/' + testId + '/approval_test_portfolios/' + sid;
      $.ajax({
        type: 'DELETE',
        url: url,
        complete(result) {
          row.remove();
          $('.modal-body').css('opacity', 1);
          $('.modal-content').find('.spinner-container').remove();
        },
      });
    });

    // Remove test fund
    $('.remove-fund-from-test').click(function() {
      $('.modal-body').css('opacity', 0.2);
      Spinner($('.modal-content'));
      let row = $(this).closest('.test-fund-row');
      let sid = $(this).attr('data-test-fund-id');
      let testId = $(this).attr('data-test-id');
      let url = '/' + accountUrl + '/compliance/approval_tests/' + testId + '/approval_test_funds/' + sid;
      $.ajax({
        type: 'DELETE',
        url: url,
        complete(result) {
          row.remove();
          $('.modal-body').css('opacity', 1);
          $('.modal-content').find('.spinner-container').remove();
        },
      });
    });

    // Remove test rule
    $('.remove-rule-from-test').off().click(function() {
      $('.modal-body').css('opacity', 0.2);
      let spinner = "<div class=\'spinner-container d-flex justify-content-center\'>";
      spinner += "<div class=\'spinner-border text-primary\'></div></div>";
      $('.modal-content').append(spinner);
      let accountUrl = $('body').attr('data-account-url');
      let row = $(this).closest('.test-rule-row');
      let sid = $(this).attr('data-test-rule-id');
      let testId = $(this).attr('data-test-id');
      let url = '/' + accountUrl + '/compliance/approval_tests/' + testId + '/approval_test_rules/' + sid;
      $.ajax({
        type: 'DELETE',
        url: url,
        complete(result) {
          row.remove();
          $('.modal-body').css('opacity', 1);
          $('.modal-content').find('.spinner-container').remove();
        },
      });
    });

    // Remove test follower
    $('.remove-follower-from-test').off().click(function() {
      $('.modal-body').css('opacity', 0.2);
      let spinner = "<div class=\'spinner-container d-flex justify-content-center\'>";
      spinner += "<div class=\'spinner-border text-primary\'></div></div>";
      $('.modal-content').append(spinner);
      let accountUrl = $('body').attr('data-account-url');
      let row = $(this).closest('.test-follower-row');
      let sid = $(this).attr('data-test-follower-id');
      let testId = $(this).attr('data-test-id');
      let url = '/' + accountUrl + '/compliance/approval_tests/' + testId + '/approval_test_followers/' + sid;
      $.ajax({
        type: 'DELETE',
        url: url,
        complete(result) {
          row.remove();
          $('.modal-body').css('opacity', 1);
          $('.modal-content').find('.spinner-container').remove();
        },
      });
    });

  }
}