import Spinner from 'src/javascripts/components/utilities/Spinner';
import StickySidebar from 'src/javascripts/components/nav/StickySidebar';
import ImpactDistControversies from 'src/javascripts/components/charts/ImpactDistControversies';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import NavQuestionnaire from 'src/javascripts/components/nav/NavQuestionnaire';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import QuestionnaireResponseAnswersTable from 'src/javascripts/components/tables/QuestionnaireResponseAnswersTable';

export default class QuestionnairesCollectionDashboardsIndex {

  constructor() {}

  render() {

    // Set account url
    const accountUrl = $('body').attr('data-account-url');

    // Add border to navbar on scroll
    NavQuestionnaire();

    // Sticky sidebar scrolling
    StickySidebar();

    // Expand when click on table of contents item
    $(window).scroll(function(e) {
      let scroll = $(window).scrollTop();
      if (scroll > 130) {
        $('.sidebar-title-info').removeClass('min-height');
      } else {
        $('.sidebar-title-info').addClass('min-height');
      }
    });
    let scroll = $(window).scrollTop();
    if (scroll > 130) {
      $('.sidebar-title-info').removeClass('min-height');
    } else {
      $('.sidebar-title-info').addClass('min-height');
    }

    // Company scorecard
    $('.company-scorecard tbody tr').click(function() {
      let url = $(this).attr('data-url');
      if (typeof url !== 'undefined') {
        window.open(url);
      }
    })

    // If tag is present
    let tag = $('.data-collection-content').attr('data-tag');
    if ((typeof tag !== 'undefined') && (tag.length > 0)) {
      let toExpand = $('#' + tag).find('.collapse');
      toExpand.collapse('show');
      $('.collapse').not(toExpand).collapse('hide');
      setTimeout(function() {
        ScrollToTag(tag);  
      }, 500);
    }

    // Questionnaire modules show
    let container = $('#questionnaireModulesList').find('.benchmarking-container');
    if (container.length > 0) {
      Spinner(container);
      let dataUrl = container.attr('data-url');
      let filters = {};
      let token = container.attr('data-token');
      if (typeof token !== 'undefined') {filters['token'] = token;}
      $.ajax({
        type: "GET",
        dataType: "script",
        url: dataUrl + '.js?' + $.param(filters)
      });
    }

    // Questionnaire benchmarking
    $('.benchmarking-category-list').on('shown.bs.collapse', function (e) {
      let container = $(this).find('.benchmarking-container');
      if (container.length > 0) {
        Spinner(container);
        let dataUrl = container.attr('data-url');
        let filters = {};
        let moduleId = container.attr('data-module-id');
        let token = container.attr('data-token');
        let itemId = container.attr('data-item-id');
        let responseId = container.attr('data-response-id');
        let questionnaireId = container.attr('data-questionnaire-id');
        if (typeof token !== 'undefined') {filters['token'] = token;}
        if (typeof itemId !== 'undefined') {filters['item_id'] = itemId;}
        if (typeof responseId !== 'undefined') {filters['response_id'] = responseId;}
        if (typeof moduleId !== 'undefined') {filters["module_id"] = moduleId;}
        if (typeof questionnaireId !== 'undefined') {filters['questionnaire_id'] = questionnaireId;}
        $.ajax({
          type: "GET",
          dataType: "script",
          url: dataUrl + '.js?' + $.param(filters)
        });
      }
    })

    if (typeof $('#impact_dist_controversies').data('c3-chart') === 'undefined') {
      let chart = new ImpactDistControversies( $('#impact_dist_controversies') );
      $.when( chart.getData() )
       .then( data => chart.render(data) );
    }

    if ($('#controversies_table_wrapper').length === 0) {
      let tableId = 'controversies_table';
      let controversiesTable = new DefaultTable($('#' + tableId));
      $.when( controversiesTable.render(tableId) ).then( status => controversiesTable.setUp(tableId) );
    }


    // Custom methodology ratings
    $('#customMethodologyRatingsList.collapse').on('shown.bs.collapse', function (e) {    
      if (e.target == this){
        let companyId = $('#customMethodologyRatingsList').attr('data-company-id');
        let url = "/companies/" + companyId + "/ratings/custom_methodology_scorecard";
        let params = {};
        Spinner($('#customMethodologyRatingsList'));
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url + $.param(params)
        });
      }
    });

    // Show table
    $('#customMethodologyTableList').on('shown.bs.collapse', function (e) {    
      let companyId = $('#customMethodologyTableList').attr('data-company-id');
      let url = "/companies/" + companyId + "/ratings/custom_methodology_table";
      Spinner($('#customMethodologyTableList'));
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url
      });
    });

    // Show peers chart
    $('#customMethodologyPeersChart').on('shown.bs.collapse', function (e) {    
      let companyId = $('#customMethodologyPeersChart').attr('data-company-id');
      let url = "/companies/" + companyId + "/ratings/custom_methodology_peers_chart";
      Spinner($('#customMethodologyPeersChart'));
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url
      });
    });

    // Show peers 
    $('#customMethodologyPeersList').on('shown.bs.collapse', function (e) {    
      let companyId = $('#customMethodologyPeersList').attr('data-company-id');
      let url = "/companies/" + companyId + "/ratings/custom_methodology_peers";
      Spinner($('#customMethodologyPeersList'));
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url
      });
    });

    // Response answers
    $('#responseDataList').on('shown.bs.collapse', function (e) {    
      if ( $('#questionnaire_response_answer_trends_table_wrapper').length === 0 ) {
        let table = new QuestionnaireResponseAnswersTable($('#questionnaire_response_answer_trends_table'));
        $.when( table.render() ).then( status => table.setUp('questionnaire_response_answer_trends_table') )
      }
    });


  }

}