export default function() {
  let user_name = $('body').attr('data-user-name');
  let user_email = $('body').attr('data-user-email');
  let account_id = $('body').attr('data-account-id');
  let account_name = $('body').attr('data-account-name');
  if (typeof user_name === 'undefined') {user_name = 'Anonymous'}
  if (typeof user_email === 'undefined') {user_email = 'Anonymous'}
  if (typeof account_id === 'undefined') {account_id = 'Anonymous'}
  if (typeof account_name === 'undefined') {account_name = 'Anonymous'}

  // Return if includes ACA or Ethos
  if ( (user_email.includes("@acaglobal")) || (user_email.includes("@ethos")) ) {
    return;
  }

  // Return if anonymous
  if (user_email === 'Anonymous') {
    return;    
  }

  let totango_options = {
    service_id: "SP-255518-01",
    user: {
      id: user_email,
      name: user_name,
    },
    account: {
      id: account_id,
      name: account_name,
    },
    enableHierarchy: false
  };
  window.totango_options = totango_options;
  var tracker_name = window.totango_options.tracker_name || "totango";
  window.totango_tmp_stack = [];
  window[tracker_name] = {
  	go: function(){
  		return -1;
  	},
  	setAccountAttributes: function(){},
  	identify: function(){},
  	track: function(t,o,n,a) {
  		window.totango_tmp_stack.push({activity:t,module:o,org:n,user:a}); return -1;
  	}
  };
  let e = document.createElement('script'); 
  e.type = 'text/javascript'; 
  e.async = true;
  e.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'tracker.totango.com/totango6.0.0.js';
  let s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(e, s);

}
