import QuestionnaireResponseApprovalRequestsTable from 'src/javascripts/components/tables/QuestionnaireResponseApprovalRequestsTable';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import NavQuestionnaire from 'src/javascripts/components/nav/NavQuestionnaire';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {genericDisplayTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class QuestionnairesResponsesApprovalRequestsIndex {

  constructor() {}

  render() {

    // Add border to navbar on scroll
    NavQuestionnaire();

    // Render table
    if ( $('#questionnaire_response_approval_requests_table_wrapper').length === 0 ) {
      let table = new QuestionnaireResponseApprovalRequestsTable($('#questionnaire_response_approval_requests_table'));
      $.when( table.render() ).then( status => table.setUp('questionnaire_response_approval_requests_table') )
    }

    // Set account id
    const accountUrl = $('body').attr('data-account-url');

    // Questions and portfolio items search
    filterTypeahead('#items_search', 'portfolio_items', '/' + accountUrl + '/portfolios/items/search?', 382, 'generic');
    filterTypeahead('#users_search', 'users', '/' + accountUrl + '/users/search?', 282, 'generic');

    // Auto-focus on expanding dropdown
    $('.dropdown').on('shown.bs.dropdown', function() {
      $(this).find('.typeahead').focus();
    });

    // On selecting a question
    $('#users_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      let url = '/' + accountUrl + '/questionnaires/responses/approval_requests';
      let params = {};
      params['approver_user_id'] = suggestion.value;
      params['item_id'] = $('#questionnaire_response_approval_requests_table').attr('data-item-id');
      params['status'] = $('#questionnaire_response_approval_requests_table').attr('data-status');
      Turbolinks.visit(url + '?' + $.param(params));
    })

    // On selecting a response
    $('#items_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      let url = '/' + accountUrl + '/questionnaires/responses/approval_requests';
      let params = {};
      params['item_id'] = suggestion.value;
      params['approver_user_id'] = $('#questionnaire_response_approval_requests_table').attr('data-user-id');
      params['status'] = $('#questionnaire_response_approval_requests_table').attr('data-status');
      Turbolinks.visit(url + '?' + $.param(params));
    })

  }

}