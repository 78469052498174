import ListCarousels from 'src/javascripts/components/lists/ListCarousels';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import NavQuestionnaire from 'src/javascripts/components/nav/NavQuestionnaire';
import Swal from 'sweetalert2';
import * as filestack from 'filestack-js';
import * as fileUpload from 'blueimp-file-upload/js/jquery.fileupload.js';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import ManualListTypeahead from 'src/javascripts/components/typeaheads/ManualListTypeahead';
import ManualListTypeaheadMultiple from 'src/javascripts/components/typeaheads/ManualListTypeaheadMultiple';
import {noResultsTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import 'summernote/dist/summernote-bs4.min.js'

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');


export default class QuestionnairesCollectionModulesShow {

  constructor() {}

  render() {

    // Set account url
    const accountUrl = $('body').attr('data-account-url');

    // Add border to navbar on scroll
    NavQuestionnaire();

    // List carousels
    ListCarousels();

    // Format display numbers in form
    NumberDisplay();

    // Summernote text editor
    $('.summernote').summernote({
      minHeight: 100,
      dialogsInBody: true,
      toolbar: [
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['insert', ['link']],
      ]}
    );

    // Disable non-changed fields before submit, so only processing new data
    let removeNonChangedFields = function() {
      $('input.new-data').each(function() {
        if (this.value == "false") {
          $(this).closest('.form-group').find('input, select, textarea').attr('disabled', true);
          $(this).closest('td').find('input, select, textarea').attr('disabled', true);
        }
      })
    }

    // Toggle new data indicator
    let toggleNewData = function(el) {
      let cardBody = el.closest('.response-card-body');
      let userId = cardBody.attr('data-user-id');
      let len = el.val.length;
      if (len > 0) {
        cardBody.attr('data-val-changed', 'true');
        cardBody.find('.user-id').attr('value', userId);
        cardBody.find('.new-data').attr('value', true);
      } else {
        cardBody.attr('data-val-changed', 'false');
        cardBody.find('.user-id').attr('value', '');
        cardBody.find('.new-data').attr('value', false);
      }
    }

    // Save and redirect
    let saveAndRedirect = function(href) {

      // Opacity on page
      $('#save_response_form .data-collection-content').css('opacity', 0.25)
      $('.submit-response-spinner-container').removeClass('d-none');
      Spinner($('.submit-response-spinner-container'));

      // Remove empty fields
      removeNonChangedFields();

      // Submit form
      $('#hidden_is_redirecting').val(true);
      $('#hiddenSaveResponse').click();

      // Now visit href
      Turbolinks.visit(href);

    }

    // Set updated at/by if present
    let setUpdatedAtCols = function(el) {
      let updatedAtInput = el.closest('tr').find('.updated-at-cell');
      if (typeof updatedAtInput !== 'undefined') {
        let updatedAtVal = updatedAtInput.attr('data-current-date');
        updatedAtInput.val(updatedAtVal);
      }
      let updatedByInput = el.closest('tr').find('.updated-by-cell');
      if (typeof updatedByInput !== 'undefined') {
        let updatedByVal = updatedByInput.attr('data-current-user');
        updatedByInput.val(updatedByVal);
      }
    }

    // Set dependent dropdown values 
    let setDependentDropdownValues = function(el) {

      // Set vars
      let answer = el.val();
      let dependentCols = el.closest('td').attr('data-dependent-column-ids');

      // Remove green bg and return if removing the answer or no answer available
      if (answer === '') { 
        el.closest('.response-table-cell').removeClass('completed');
        return;
      }

      // Return if no dependent columns
      if ((typeof dependentCols === 'undefined') || (JSON.parse(dependentCols).length === 0)) {return;}

      // Iterate through any dependent columns
      dependentCols = JSON.parse(dependentCols);
      $.each(dependentCols, function(i, colId) {

        // Set dependent cell
        let dependentCell = el.closest('tr').find('td[data-column-id=' + colId + ']');
        if (typeof dependentCell === 'undefined') {return;}

        // Set the associated select (dropdown) and options
        let select = dependentCell.find('select');
        let cellOpts = select.attr('data-cell-options');
        if (typeof cellOpts === 'undefined') {return;}
        cellOpts = JSON.parse(cellOpts);

        // Set the dropdown options that should be set to visible
        let visible = []
        $.each(cellOpts[answer], function(i, o) { visible.push(o[0]); })

        // Remove select value if not in visible
        let selectedVal = select.val();
        if (jQuery.inArray(selectedVal, visible) === -1) { 
          select.val("").change(); 
          select.closest('.response-table-cell').removeClass('completed');
        }

        // Adjust display (visibility) of dropdown options
        select.find('option').each(function(i, o) {
          if (jQuery.inArray(o.value, visible) !== -1) {
            o.style.display = 'block';
          } else if (o.value === "") {
            o.style.display = 'block';
          } else {
            o.style.display = 'none';
          }
        })

      })

    }

    // Manual list typeaheads
    if ($('.manual-list-typeahead').length !== 0) {
      $('.manual-list-typeahead').each(function(el){
        let id = $(this).attr('id');
        let t = new ManualListTypeahead(el);
        t.render(id)
      });
    }

    // Manual list typeaheads - multiple selection allowed
    if ($('.manual-list-typeahead-multiple').length !== 0) {
      $('.manual-list-typeahead-multiple').each(function(el){
        let id = $(this).attr('id');
        let t = new ManualListTypeaheadMultiple(el);
        t.render(id)
      });
    }

    // On select a typeahead
    $('.typeahead').click(function() {
      $(this).typeahead('val', '');
      $(this).focus();
    });

    // Apply a row to all others
    $('.apply-row-to-all').click(function() {
      $(this).closest('tr').find('td').each(function(index) {
        let input = $(this).find('input.answer-text');
        let select = $(this).find('select.answer-text');
        if (input.length !== 0) {
          let val = input.val();
          $(this).closest('tbody').find('tr').each(function() {
            let relatedTd = $(this).find('td')[index];
            let input = $(relatedTd).find('input.answer-text')
            input.val(val);
            $(relatedTd).find('.twitter-typeahead input').val(val);
            toggleNewData(input);
            setUpdatedAtCols(input);
          })
        }
      });
    });

    // Filter SASB industry by sector, when sector selected
    let sasbSector = $('.card-body[data-question-key=\'SICS.SECTOR\']').find('select.answer-text');
    if (typeof sasbSector != 'undefined') {
      sasbSector.change(function() {

        // Set SASB industry select object
        let sasbIndustry = $('.card-body[data-question-key=\'SICS.INDUSTRY\']').find('select.answer-text');

        // Restore options if removed
        let industryOpts = sasbIndustry.data('industry-options');

        if (typeof industryOpts !== 'undefined') {
          sasbIndustry.find('option').each(function() {
            $(this).remove();
          });
          $.each(industryOpts, function(i) {
            let option = industryOpts[i];
            $(sasbIndustry).append($('<option>').text(option.text).val(option.value));
          });
        }

        // Filter to selected sector
        let allIndustries = [];
        let sector = sasbSector.val();
        if (sector !== '') {
          sasbIndustry.find('option').each(function() {
            let val = $(this).val();
            let text = $(this).text();
            allIndustries.push({value: val, text: text});
            if (text.indexOf(sector) <= 0) { $(this).remove(); }
          });
          sasbIndustry.data('industry-options', allIndustries);
        }
      })


    }

    // Save before navigate to new element/section
    // Only save if there are changes to save
    $('a.list-title').click(function(e) {
      e.preventDefault();
      let href = $(this).attr('href');
      let valsChanged = $('.response-card-body[data-val-changed=true]');
      if (valsChanged.length > 0) {
        saveAndRedirect(href);        
      } else {
        Turbolinks.visit(href);
      }
    })

    // Save before navigating to next section
    $('a.visit-module-link').click(function(e) {
      e.preventDefault();
      let href = $(this).attr('href');
      let valsChanged = $('.response-card-body[data-val-changed=true]');
      if (valsChanged.length > 0) {
        saveAndRedirect(href);        
      } else {
        Turbolinks.visit(href);
      }
    })

    // Save before navigating to modules home
    $('a.visit-modules-home-link').click(function(e) {
      e.preventDefault();
      let href = $(this).attr('href');
      let valsChanged = $('.response-card-body[data-val-changed=true]');
      if (valsChanged.length > 0) {
        saveAndRedirect(href);        
      } else {
        Turbolinks.visit(href);
      }
    })

    // Indicate value changed on update
    $('input, textarea').keyup(function() {
      let el = $(this);
      toggleNewData(el);
    });

    // Indicate value changed on update
    $('select:not(.cell-dropdown-option)').change(function() {
      let el = $(this);
      toggleNewData(el);
    });

    // Update dependent dropdowns on select
    $('select.cell-dropdown-option').change(function() {
      let el = $(this);
      toggleNewData(el);

      // Set dependent cell dropdowns
      setDependentDropdownValues(el);

      // Update associated updated at/by cols if present
      setUpdatedAtCols(el);

    });

    // Update associated updated at/by cols if present
    $('td input, td textarea').keyup(function() {
      let el = $(this);
      setUpdatedAtCols(el);
    });

    // Save on blur of field with conditions
    $('.input-group[data-has-dependencies=\'true\'').find('input, textarea').blur(function() {
      let valsChanged = $('.response-card-body[data-val-changed=true]');
      if (valsChanged.length > 0) {
        $('#save_response_form .data-collection-content').css('opacity', 0.25)
        $('.submit-response-spinner-container').removeClass('d-none');
        Spinner($('.submit-response-spinner-container'));
        removeNonChangedFields();
        $('#hiddenSaveResponse').click();
      }
    });
    $('.input-group[data-has-dependencies=\'true\'').find('select').change(function() {
      let valsChanged = $('.response-card-body[data-val-changed=true]');
      if (valsChanged.length > 0) {
        $('#save_response_form .data-collection-content').css('opacity', 0.25)
        $('.submit-response-spinner-container').removeClass('d-none');
        Spinner($('.submit-response-spinner-container'));
        removeNonChangedFields();
        $('#hiddenSaveResponse').click();
      }
    });

    // Save on blur of cell with conditions
    $('.response-table-cell[data-has-totals=\'true\'').find('input, textarea').blur(function() {
      let valsChanged = $('.response-card-body[data-val-changed=true]');
      if (valsChanged.length > 0) {
        $('#save_response_form .data-collection-content').css('opacity', 0.25)
        $('.submit-response-spinner-container').removeClass('d-none');
        Spinner($('.submit-response-spinner-container'));
        removeNonChangedFields();
        $('#hiddenSaveResponse').click();
      }
    });
    $('.response-table-cell[data-has-totals=\'true\'').find('select').change(function() {
      let valsChanged = $('.response-card-body[data-val-changed=true]');
      if (valsChanged.length > 0) {
        $('#save_response_form .data-collection-content').css('opacity', 0.25)
        $('.submit-response-spinner-container').removeClass('d-none');
        Spinner($('.submit-response-spinner-container'));
        removeNonChangedFields();
        $('#hiddenSaveResponse').click();
      }
    });


    // Update dependent dropdowns on page load
    $('select.cell-dropdown-option').each(function() {
      setDependentDropdownValues($(this));
    })

    // Disable inputs if submitted
    let submitted = $('#hidden_submit_questionnaire').val();
    let canManageQuestionnaires = $('#hidden_submit_questionnaire').attr("data-can-manage-questionnaires");
    let status = $('.polling-indicator').attr('data-status');
    if ((canManageQuestionnaires === "false") || (submitted === 'true')) {
      $('input, select, textarea').attr('disabled', true);
      $('.mark-not-applicable').removeClass('mark-not-applicable');
      $('.apply-date-to-all').removeClass('apply-date-to-all');
      $('.apply-provided-date-to-all').removeClass('apply-provided-date-to-all');
      let sumCont = $('.summernote');
      $.each(sumCont, function() {
        $(this).summernote('disable');
      })
    }

    // Copy previous data
    $('.copy-previous-answer').click(function() {
      let previousResponseId = $(this).attr('data-previous-response-id');
      let responseId = $(this).attr('data-response-id');
      let answerId = $(this).attr('data-answer-id');
      let params = {};
      params['previous_response_id'] = previousResponseId;
      params['answer_id'] = answerId;
      let url = "/" + accountUrl + "/data-collection/responses/" + responseId + "/answers/prefill?" + $.param(params);

      // Opacity on page
      $('.modal.prefill-modal').modal('hide');
      $('#save_response_form .data-collection-content').css('opacity', 0.25)
      $('.submit-response-spinner-container').removeClass('d-none');
      Spinner($('.submit-response-spinner-container'));

      // Indicate should update included section
      let cardBody = $(this).closest('.card').find('.response-card-body');
      let userId = cardBody.attr('data-user-id');
      cardBody.attr('data-val-changed', 'true');
      cardBody.find('.user-id').attr('value', userId);
      cardBody.find('.new-data').attr('value', true);

      // Submit
      $.ajax({
        type: "POST",
        dataType: "script",
        timeout: 3000,
        url: url
      })
    })

    // Mark not applicable
    $('.mark-not-applicable').click(function() {
      let el = $(this);
      toggleNewData(el);
      let hiddenNa = el.closest('.input-group').find('.hidden-not-applicable');
      if (typeof hiddenNa !== 'undefined') {
        let responseText = el.closest('.input-group').find('.answer-text');
        let numericHidden = el.closest('.input-group').find('.numeric-hidden-field');
        if (el.attr('data-selected') === 'true') {
          responseText.removeClass('d-none');
          hiddenNa.addClass('d-none');
          numericHidden.val(numericHidden.attr('data-original-value'));
          responseText.val("");
          el.removeClass('bg-gray100').addClass('bg-white').attr('data-selected', 'false');
          el.attr('data-original-title', 'Click to mark this question as not applicable. Please provide explanation in the comment if necessary.')
        } else {
          responseText.addClass('d-none');
          hiddenNa.removeClass('d-none');
          numericHidden.attr("data-original-value", numericHidden.val()).val("Not applicable");
          responseText.val("Not applicable");
          el.removeClass('bg-white').addClass('bg-gray100').attr('data-selected', 'true');
          el.attr('data-original-title', 'Click to remove the \'Not applicable\' designation.')
        }
      } else {
        if (el.attr('data-selected') === 'true') {
          el.closest('.input-group').find('input, select').val('').change();
          el.removeClass('bg-gray100').addClass('bg-white').attr('data-selected', 'false');
          el.attr('data-original-title', 'Click to mark this question as not applicable. Please provide explanation in the comment if necessary.')
        } else {
          el.closest('.input-group').find('input, select').val('Not applicable').change();
          el.removeClass('bg-white').addClass('bg-gray100').attr('data-selected', 'true');
          el.attr('data-original-title', 'Click to remove the \'Not applicable\' designation.')
        }
      }
      el.tooltip('hide');
    });

    // Copy an "as-of" date to all questions in a questionnaire
    $('.apply-date-to-all').click(function() {
      let dataAsOf = $(this).closest('.input-group').find('input').val();
      let responseId = $(this).attr('data-response-id');
      let params = {};
      params['data_as_of'] = dataAsOf;
      let url = "/" + accountUrl + "/data-collection/responses/" + responseId + "/answers/copy_as_of_date?" + $.param(params);

      // Opacity on page
      $('#save_response_form .data-collection-content').css('opacity', 0.25)
      $('.submit-response-spinner-container').removeClass('d-none');
      Spinner($('.submit-response-spinner-container'));

      // Submit
      $.ajax({
        type: "POST",
        dataType: "application/json",
        timeout: 3000,
        url: url,
        complete(result) { 
          let resultStatus = JSON.parse(result.responseText);
          let dataAsOf = resultStatus.data_as_of;
          $('.data-as-of').val(dataAsOf);
          $('#save_response_form .data-collection-content').css('opacity', 1)
          $('.submit-response-spinner-container').addClass('d-none');
          $('.submit-response-spinner-container').find('.spinner-container').remove();
        }
      })

    });

    // Copy an "as-of" date to all questions in a questionnaire
    $('.apply-provided-date-to-all').click(function() {
      let dataProvidedBy = $(this).closest('.input-group').find('.data-provided-by').val();
      let dataProvidedDate = $(this).closest('.input-group').find('.data-provided-date').val();
      let responseId = $(this).attr('data-response-id');
      let params = {};
      params['data_provided_by'] = dataProvidedBy;
      params['data_provided_date'] = dataProvidedDate;
      let url = "/" + accountUrl + "/data-collection/responses/" + responseId + "/answers/copy_as_of_date?" + $.param(params);

      // Opacity on page
      $('#save_response_form .data-collection-content').css('opacity', 0.25)
      $('.submit-response-spinner-container').removeClass('d-none');
      Spinner($('.submit-response-spinner-container'));

      // Submit
      $.ajax({
        type: "POST",
        dataType: "application/json",
        timeout: 3000,
        url: url,
        complete(result) { 
          let resultStatus = JSON.parse(result.responseText);
          let dataProvidedBy = resultStatus.data_provided_by;
          let dataProvidedDate = resultStatus.data_provided_date;
          $('.data-provided-by').val(dataProvidedBy);
          $('.data-provided-date').val(dataProvidedDate);
          $('#save_response_form .data-collection-content').css('opacity', 1)
          $('.submit-response-spinner-container').addClass('d-none');
          $('.submit-response-spinner-container').find('.spinner-container').remove();
        }
      })

    });

    // Filter typeaheads for documents
    let responseId = $('.documents-typeahead').attr('data-response-id');
    let answerId = $('.documents-typeahead').attr('data-answer-id');
    let searchUrl = "/" + accountUrl + "/data-collection/responses/" + responseId + "/documents/search?"
    filterTypeahead('.documents-typeahead', 'documents', searchUrl, 143, 'generic');
    $('.documents-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
      let filePicker = $(this).closest('.supporting-documentation').find('.response-document-filepicker');
      let responseId = filePicker.attr('data-response-id');
      let answerId = filePicker.attr('data-answer-id');
      let responseDocumentId = suggestion.value;

      // Only continue if not already added
      let exists = $(this).closest('.response-card-body').find('.response-document-row[data-document-id=\'' + responseDocumentId + '\']');
      if (exists.length !== 0) {
        ToastCustom('Already attached', 'You have already attached that document to this response');

        // Blur input 
        $(this).typeahead("val", "");
        $(this).blur();

      } else {

        // Set card body to indicate value changed for reloading page
        let cardBody = $(this).closest('.card-body.response-card-body');
        cardBody.attr('data-val-changed', 'true');

        // Set spinner so user knows in progress
        cardBody.addClass('opaque');
        Spinner($(this).closest('.card'));

        // Upload file
        let url = "/" + accountUrl + '/data-collection/responses/' + responseId + '/answer_documents';
        let formData = {}
        formData['answer_id'] = answerId;
        formData['response_document_id'] = responseDocumentId;

        // Update local attribute
        return $.ajax({
          type: 'POST',
          url: url,
          dataType: "script",
          data: formData,
        });

      }

    });

    // Blur on select
    $('.documents-typeahead').bind('typeahead:select', function(ev, suggestion) {
      $('.typeahead').typeahead("val", "");
      $('.typeahead').blur();
    });

    // On submit
    $('#submitQuestionnaireResponse').click(function(el) {
      el.preventDefault();

      // Confirm and then continue
      return Swal.fire({
        title: "Confirm submission",
        text: "Please confirm that you would like to finalize and submit the questionnaire. If you need others on your account to approve the questionnaire, you can specify them in the next step.",
        animation: false,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-light border',
          popup: 'animated fadeIn faster'
        }
      }).then((result) => {
        if (result.value) {
          // Add field to form to indicate we're submitting it
          $('#hidden_submit_questionnaire').val("true");

          // Opacity on page
          $('#save_response_form .data-collection-content').css('opacity', 0.25)
          $('.submit-response-spinner-container').removeClass('d-none');
          Spinner($('.submit-response-spinner-container'));

          // Remove non-changed fields
          removeNonChangedFields();

          // Submit form
          $('#hiddenSaveResponse').click();

        }
      });

    })

    // On submit
    $('.unsubmit-questionnaire-response').click(function(el) {
      el.preventDefault();

      // Confirm and then continue
      return Swal.fire({
        title: "Confirm",
        text: "Please confirm that you would like to change the status of this questionnaire back to 'draft'.",
        animation: false,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-light border',
          popup: 'animated fadeIn faster'
        }
      }).then((result) => {
        if (result.value) {
          
          // Add field to form to indicate we're submitting it
          $('#hidden_submit_questionnaire').val("false");

          // Opacity on page
          $('#save_response_form .data-collection-content').css('opacity', 0.25)
          $('.submit-response-spinner-container').removeClass('d-none');
          Spinner($('.submit-response-spinner-container'));

          // Remove non-changed fields
          removeNonChangedFields();

          // Submit form - first need to enable fields, otherwise rails UJS doesn't submit form
          $('input, select, textarea').attr('disabled', false);
          $('#hiddenSaveResponse').click();

        }
      });

    })

    // On save
    $('#saveQuestionnaireResponse').click(function(el) {
      el.preventDefault();

      // Check if any values changed      
      let valChanged = $('.response-card-body[data-val-changed=\'true\']');

      // Opacity on page
      $('#save_response_form .data-collection-content').css('opacity', 0.25)
      $('.submit-response-spinner-container').removeClass('d-none');
      Spinner($('.submit-response-spinner-container'));

      // Remove non-changed fields
      removeNonChangedFields();

      // Submit form
      $('#hiddenSaveResponse').click();

    })

    // Update hidden fields on type of value/number text field
    $(".value-text").on("change paste keyup", function() {

      // skip for arrow keys
      if(event.which >= 37 && event.which <= 40) return;

      // Update related numeric input (remove comma, return to value)
      let numericInput = $(this).closest('.form-group').find('.numeric-hidden-field');
      let numericVal = parseFloat( $(this).val() );

      // Divide by 100 if percentage
      if ($(this).hasClass('number-percentage')) {
        numericVal = numericVal / 100;
      }

      // Update associated hidden field
      if (Number.isNaN(numericVal)) {
        numericInput.val('');
      } else {
        numericInput.val(numericVal);
      }

    });

    // Add percentage and cap amount if percentage
    $('.number-percentage').on("blur", function() {
      let currentVal = parseFloat( $(this).val() );
      if (Number.isNaN(currentVal)) {
        $(this).val('');
      } else {
        $(this).val(currentVal + '%');
      }
    });

    // Upload file
    // Set environment and account id for AWS path vars
    $('.response-document-filepicker').each(function(i, elem) {
      let fileInput     = $(elem);
      let filePicker    = $(this);
      let dropZone      = $(this).closest('.filepicker-container');
      let env           = fileInput.data("env");
      let primaryColor  = fileInput.data("primary-color");
      let accountUrl       = fileInput.data("account-url");
      var progressBar   = $("<div class='bar'></div>");
      var barContainer  = $("<div class='progress'></div>").append(progressBar);
      $(this).closest('.supporting-documentation').find('.file-upload-progress').append(barContainer);
      fileInput.fileupload({
        dropZone:         dropZone,
        fileInput:        fileInput,
        url:              fileInput.data('url'),
        type:             'POST',
        autoUpload:       true,
        formData:         fileInput.data('form-data'),
        paramName:        'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
        dataType:         'XML',  // S3 returns XML if success_action_status is set to 201
        replaceFileInput: false,
        progressall: function (e, data) {
          var progress = parseInt(data.loaded / data.total * 100, 10);
          progressBar.css('width', progress + '%')
        },
        start: function (e) {
          progressBar.
            css('background', primaryColor).
            css('display', 'block').
            css('width', '0%').
            css("height", "25px");
        },
        done: function(e, data) {
          progressBar.text("");

          // extract key from response
          let key = $(data.jqXHR.responseXML).find("Key").text();
          let accountUrl = filePicker.attr('data-account-url');
          let responseId = filePicker.attr('data-response-id');
          let answerId = filePicker.attr('data-answer-id');

          // Set card body to indicate value changed for reloading page
          let cardBody = $(this).closest('.card-body.response-card-body');
          cardBody.attr('data-val-changed', 'true');

          // Set spinner so user knows in progress
          cardBody.addClass('opaque');
          Spinner($(this).closest('.card'));

          // Upload file
          let url = "/" + accountUrl + '/data-collection/responses/' + responseId + '/documents';
          let formData = {}
          formData['file'] = {}
          formData['file']['key'] = key;
          formData['answer_id'] = answerId;

          // Update local attribute
          return $.ajax({
            type: 'POST',
            url: url,
            dataType: "script",
            data: formData,
          });

        },
        fail: function(e, data) {
          progressBar.
            css("background", "red").
            text("Failed");
        }

      });

    });

    // Set a source link
    $('.supporting-links').keyup(function(i, elem) {
      let link = $(this).val();
      if (link.length > 3) {
        let accountUrl = $('body').attr("data-account-url");
        let responseId = $(this).attr('data-response-id');
        let answerId = $(this).attr('data-answer-id');

        // Set card body to indicate value changed for reloading page
        let cardBody = $(this).closest('.card-body.response-card-body');
        cardBody.attr('data-val-changed', 'true');

        // Set spinner so user knows in progress
        cardBody.addClass('opaque');
        Spinner($(this).closest('.card'));

        // Blur
        $(this).val('').blur();

        // Upload file
        let url = "/" + accountUrl + '/data-collection/responses/' + responseId + '/documents';
        let formData = {}
        formData['file'] = {}
        formData['file_type'] = "link";
        formData['file']['key'] = link;
        formData['answer_id'] = answerId;

        // Update local attribute
        $.ajax({
          type: 'POST',
          url: url,
          dataType: "script",
          data: formData,
        });
      }

    });


    // Timed save
    let timedSavePoll = (pollId) => {

      // Get polling indicator on page
      let savingPoll = $('.polling-indicator').attr("data-saving-poll");
      
      // Only continue if status indicator present (if should poll for recs is true)
      if (savingPoll === pollId) {

        // Check if any values changed      
        let valChanged = $('.response-card-body[data-val-changed=\'true\']');

        // Submit form data
        let responseId = $('.polling-indicator').attr('data-response-id');
        let url = "/" + accountUrl + "/data-collection/responses/" + responseId + '/save';
        $.ajax({
          type: "PATCH",
          dataType: "application/json",
          data: $("#save_response_form").serialize(),
          timeout: 10000,
          url: url,
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            if (responseText.conditional_questions_changed === true) {
              let url = window.location.toString();
              let urlparts = url.split('?');   
              if (urlparts.length >= 2) { 
                url = url + '&scroll=true'
              } else {
                url = url + '?scroll=true';
              }
              Turbolinks.visit(url, { action: 'replace' });

            } else {

              setTimeout(function() { timedSavePoll(pollId) }, 10000);

              // Set Api url to get data
              let responseId = $('.polling-indicator').attr('data-response-id');
              let includedSectionId = $('.polling-indicator').attr('data-included-section-id');
              let accountUrl = $('body').attr('data-account-url');
              let params = {};
              params['included_section_id'] = includedSectionId;
              let url = '/' + accountUrl + '/data-collection/responses/' + responseId + '/poll.js?' + $.param(params);

              if (typeof includedSectionId !== 'undefined') {

                // Unhide polling spinner
                $('.polling-indicator').find('.spinner-border').removeClass('d-none');

                // Execute ajax request (using js erb template to render content so can control profile styling more easily)
                // Must specify '.js' otherwise processes as JSON
                $.ajax({
                  type: "POST",
                  dataType: "script",
                  timeout: 10000,
                  url: url
                });
              }

            }

          }
        });

      }

    };

    // Poll server for status of mapped items, if generating message present
    let rand = 1 + Math.floor(Math.random() * 1000000);
    rand = rand.toString();
    $('.polling-indicator').attr("data-saving-poll", rand);
    setTimeout(function() { timedSavePoll(rand) }, 10000);

  }

}