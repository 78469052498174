import Chart from 'src/javascripts/components/Chart';
import Spinner from 'src/javascripts/components/utilities/Spinner';

// Add topo json (D3 map)
const topojson = require('src/javascripts/vendor/topojson.v2.min');
const d3 = require('src/javascripts/vendor/d3.v4.min');


export default class ClimateHazardsChart extends Chart {

  getData() {

    // Set url
    let params = {};
    let accountUrl = $('body').attr('data-account-url');
    let portfolioId = this.el.attr('data-portfolio-id');
    let modelId = this.el.attr('data-model-id');
    let metricId = this.el.attr('data-metric-id');
    let url;
    if (typeof metricId !== 'undefined') {params['metric_id'] = metricId;}
    if (typeof modelId !== 'undefined') {
      params['model_id'] = modelId;
      url = "/" + accountUrl + "/locations?" + $.param(params);
    } else {
      url = "/" + accountUrl + "/portfolios/" + portfolioId + "/locations?" + $.param(params);
    }

    // Set chartId
    let chartId = this.chartId;

    // Set spinner and opacity
    Spinner($('#' + chartId));
    $('#' + chartId).find('.us-counties-svg').css('opacity', 0.25);

    // Get data
    return $.getJSON(url);

  }

  render(data) {

    // Set chartId
    const chartId = this.chartId;

    // Set data by location
    let locations = data.locations;

    // Append a tooltip with 0 opacity
    $('body').append('<div class=\'climate-tooltip\'></div>');
    $('.climate-tooltip').css('opacity', 0).css('display', 'none');

    // Update text and positioning of tooltip
    let appendTooltipText = function(location) {

      // Set company name if present
      let companyText;
      if (location.company !== null) {
        companyText = "<tr><td>Company: </td><td><a href=\'" + location.company_link + "\' target=\'_blank\'>" + location.company + "</a></td></tr>";
      } else {
        companyText = "<tr><td>Company: </td><td></td></tr>";
      }

      let tooltipText = "<div class=\'tooltip-header bg-primary font-weight-bold\'>" + location.street + ", " + location.city + ", " + location.state + "</div>";
      tooltipText += "<div class=\'tooltip-body bg-white p-0\'>";
      tooltipText += "<table class=\'table mb-0\'><tbody>";
      tooltipText += companyText;
      tooltipText += "<tr><td>Census tract: </td><td>" + location.census_tract + "</td></tr>";
      tooltipText += "<tr><td>County: </td><td>" + location.county_name + "</td></tr>";
      tooltipText += "<tr><td>Metric: </td><td>" + location.metric + "</td></tr>";
      tooltipText += "<tr><td>Data: </td><td>" + location.score_base_formatted + "</td></tr>";
      tooltipText += "<tr><td>Risk level: </td><td><span class=\'font-weight-bold " + location.color + "\'>" + location.risk_level + "</span></td></tr>";
      tooltipText += "<tr><td>Location profile: </td><td><a href=\'" + location.location_link + "\' target=\'_blank\'>Location profile</a></td></tr>";
      tooltipText += "</tbody></table></div>";

      // Set tooltip by right or left
      let docWidth = $(document).width();
      let dir;
      let xOffset;
      if (d3.event.pageX > docWidth / 2) {
        dir = "right"
        xOffset = docWidth - d3.event.pageX;
      } else {
        dir = "left"
        xOffset = d3.event.pageX;
      }

      // Get existing if present
      d3.select(".climate-tooltip")
        .style("left", null)
        .style("right", null)
        .style(dir, (xOffset) + "px")
        .style("top", (d3.event.pageY) + "px")
        .html(tooltipText)
        .transition()
        .duration(200)
        .style("opacity", 1)
        .style("display", "block")
        .style("z-index", 53)

    }


    // Set map variables
    let countyMap = d3.select("svg.us-counties-svg");
    let path = d3.geoPath();
    let mapUrl = "https://d3js.org/us-10m.v1.json";

    // Render chart
    d3.json(mapUrl, function(error, us) {
      if (error) throw error;
    
      // Append
      countyMap.append("g")
          .attr("class", "counties")
        .selectAll("path")
        .data(topojson.feature(us, us.objects.counties).features)
        .enter().append("path")
          .attr("d", path);
    
      countyMap.append("path")
          .attr("class", "county-borders")
          .attr("d", path(topojson.mesh(us, us.objects.counties, function(a, b) { return a !== b; })));

      // Set county tract codes and classes
      $.each(locations, function(i,location) {
        
        countyMap.append("path")
          .attr("class", location.color)
          .datum(topojson.merge(us, us.objects.counties.geometries.filter(function(d) { return d.id === location.county_fips; })))
          .attr("d", path)
          .on("mouseover", function(d){
            appendTooltipText(location);
          }).on('mouseleave', function(d){
            if ($('.climate-tooltip:hover').length === 0) {
              d3.select('.climate-tooltip').transition().duration(500).style("opacity", 0).style("display", "block").style("z-index", -1);  
            }
          });

      });

      // Remove spinner after chart has drawn
      $('#' + chartId).find('.spinner-container').remove();
      $('#' + chartId).find('.us-counties-svg').css('opacity', 1);

      // Remove opacity from search bar if present
      $('.add-initial-address').css('opacity', 1);

      // Update title
      $('#mapTitle').empty().append(data.metric_name);
      $('#mapDescription').empty().append(data.metric_description);
      $('#mapInfoLink').attr('href', '/metrics/' + data.metric_id)

    });

    // On mouseout of tooltip
    $('.climate-tooltip').on('mouseleave', function() {
      d3.select('.climate-tooltip').transition().duration(500).style("opacity", 0).style("display", "block").style("z-index", -1);
    })


  }
};
