import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import Spinner from 'src/javascripts/components/utilities/Spinner';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class ManualListTypeahead {

  constructor(el) { this.el = el; }

  render(id) {

    // Toggle new data indicator
    let toggleNewData = function(el) {
      let cardBody = el.closest('.response-card-body');
      let userId = cardBody.attr('data-user-id');
      let len = el.val.length;
      if (len > 0) {
        cardBody.attr('data-val-changed', 'true');
        cardBody.find('.user-id').attr('value', userId);
        cardBody.find('.new-data').attr('value', true);
      } else {
        cardBody.attr('data-val-changed', 'false');
        cardBody.find('.user-id').attr('value', '');
        cardBody.find('.new-data').attr('value', false);
      }
    }

		let listOptions = [];
		let i = 0;
		let dataOpts = JSON.parse($('#' + id).attr('data-list-options'));
    while (i < dataOpts.length) {
      listOptions.push(dataOpts[i]);
      i++;
    }

		var substringMatcher = function(strs) {
		  return function findMatches(q, cb) {

		    // an array that will be populated with substring matches
		    let matches = [];

		    // regex used to determine if a string contains the substring `q`
		    let substrRegex = new RegExp(q, 'i');

		    // iterate through the pool of strings and for any string that
		    // contains the substring `q`, add it to the `matches` array
		    $.each(strs, function(i, str) {
		      if (substrRegex.test(str)) {
		        matches.push(str);
		      }
		    });

		    cb(matches);
		  };
		};

		$('#' + id).typeahead({
		  hint: true,
		  highlight: true,
		  minLength: 0
		},
		{
		  name: 'list',
    	limit: 554,
		  source: substringMatcher(listOptions)
		});

	  $('#' + id).bind('typeahead:beforeselect', function(ev, suggestion) {
	  	let ig = $(this).closest('td');
	  	if (ig.length === 0) {ig = $(this).closest('.input-group')}
	  	let hidden = ig.find('input.answer-text');
	  	hidden.val(suggestion);
	  	toggleNewData(hidden);
	  });

	}
}