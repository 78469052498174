import Spinner from 'src/javascripts/components/utilities/Spinner';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import ExpandContent from 'src/javascripts/components/utilities/ExpandContent';
import ExpandExcerpt from 'src/javascripts/components/utilities/ExpandExcerpt';
import StickySidebar from 'src/javascripts/components/nav/StickySidebar';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import ClimateHazardsChart from 'src/javascripts/components/charts/ClimateHazardsChart';
import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';


export default class ResearchLocationsIndex {

  constructor() {}

  render() {

    // Set account url
    const accountUrl = $('body').attr('data-account-url');

    // Expand/minimize profile details
    ExpandContent();

    // Number display
    NumberDisplay();

    // Update value on input
    $('#value_of_account_modal').blur(function(){
      let val = $(this).val();
      $(this).closest('form').find('input#hidden_location_model_value').val(val);
    })

    // Sticky sidebar scrolling
    StickySidebar();

    // Expand when click on table of contents item
    $(window).scroll(function(e) {
      let scroll = $(window).scrollTop();
      if (scroll > 130) {
        $('.sidebar-title-info').removeClass('min-height');
      } else {
        $('.sidebar-title-info').addClass('min-height');
      }
    });
    let scroll = $(window).scrollTop();
    if (scroll > 130) {
      $('.sidebar-title-info').removeClass('min-height');
    } else {
      $('.sidebar-title-info').addClass('min-height');
    }

    // Scroll to anchor if present
    let anchor = $('#profileTabContent').attr('data-anchor');
    if (typeof anchor !== 'undefined') {
      let toExpand = $('#' + anchor).find('.collapse');
      toExpand.collapse('show');
      $('.collapse').not(toExpand).collapse('hide');
      setTimeout(function() {
        ScrollToTag(anchor);
      }, 500);
    }

    // Render climate hazards chart if not already loaded
    if ( ($('#climate_hazards_chart').length !== 0) ) {
      let chart = new ClimateHazardsChart( $('#climate_hazards_chart') );
      $.when( chart.getData() )
       .then( data => chart.render(data) );
    };

    if (($('#locations_table').length > 0) && ($('#locations_table tbody td').length === 0)) {
      let table = new DefaultTable($('#locations_table'));
      $.when( table.render() ).then( status => table.setUp('locations_table') );
    }

    // Reload on change of metric
    $('.reload-location-analysis').click(function() {
      let metricId = $(this).attr('data-metric-id');
      $('#climate_hazards_chart').attr('data-metric-id', metricId);
      $('#locations_table').attr('data-metric-id', metricId);

      // Reload chart
      $('.us-counties-svg').empty();
      let newChart = new ClimateHazardsChart( $('#climate_hazards_chart') );
      $.when( newChart.getData() )
       .then( data => newChart.render(data) );

      // Build url, use to get filtered data with server-side processing
      let dataUrl = "/" + accountUrl + "/locations/model_items";
      let modelId = $('#locations_table').attr('data-model-id');
      let params = {};
      params['model_id'] = modelId;
      params['metric_id'] = metricId;
      dataUrl = dataUrl + '?' + $.param(params);

      // Reload table
      $('#locations_table').DataTable().ajax.url(dataUrl).load();

    })

    // Google Places API autocomplete
    // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete
    function initMap() {
      const input = document.getElementById("addressLine");
      const options = {
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
      };
      const autocomplete = new google.maps.places.Autocomplete(input, options);
    }

    // Init on show of modal
    $('#addAddressModal').on('show.bs.modal', function () {
      initMap();

      // On select
      $('#addressLine').on('change', function() {
        let len = $(this).val().length;
        if (len > 0) {
          $('.disabled-address-submit').addClass('d-none');
          $('.enabled-address-submit').removeClass('d-none');
        } else {
          $('.disabled-address-submit').removeClass('d-none');
          $('.enabled-address-submit').addClass('d-none');
        }
      });

    });

    // If initial address add
    if ($('#addAddressModal').length === 0) {
      initMap();
      $('#addressLine').on('change', function() {
        let len = $(this).val().length;
        if (len > 0) {
          $('.disabled-address-submit').addClass('d-none');
          $('.enabled-address-submit').removeClass('d-none');
        } else {
          $('.disabled-address-submit').removeClass('d-none');
          $('.enabled-address-submit').addClass('d-none');
        }
      });
    }

    // Validations
    $('#addAddressModal').on('shown.bs.modal', function () {
      $('#addAddressModal form').enableClientSideValidations();
    });


  }

}