import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import AddRowHoverButtons from 'src/javascripts/components/tables/helpers/AddRowHoverButtons';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';

export default class SecurityWeightsTable extends Table {

  render(tableId) {

    // Build url, use to get filtered data with server-side processing
    const dataUrl = $('#' + tableId).attr('data-api-url');

    // Set additional data vars (used below in table creation)
    const cols = GetCols(this.el);
    let colLen = cols.length;

    // Render table
    // Most table options handled through data attrs on table element
    // https://datatables.net/manual/options
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      ajax: dataUrl,
      serverSide: true,
      deferRender: true,
      dom: 'tp',
      columns: cols,
      paging: true,
      pageLength: 10,
      searching: true,
      ordering: true,
      order: [[colLen - 2, 'desc']],
      language: {
        infoEmpty: "Couldn't find any holdings data. Please contact us with any questions.",
        emptyTable: "Couldn't find any holdings data. Please contact us with any questions.",
        zeroRecords: "Couldn't find any holdings data. Please contact us with any questions."
      },

      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        AddRowDataAttrs(row, data);

        if (data.record_id !== null) {

          // Add hoverlink buttons to rows
          let link = '/' + data.record_type + '/' + data.record_id + '?formula_id=' + data.formula_id;
          let name = 'View profile';
          let linkObjects = [{link: link, name: name, remote: false, target: '_blank'}];
          AddRowHoverButtons(row, linkObjects);

        } else {

          $(row).addClass('no-click');

        }

      },

      initComplete: function( settings ) {

        // initComplete runs once table has been fully drawn, with ajax async
        // https://datatables.net/reference/option/initComplete
        // Remove spinner after table has drawn
        let card = $('#' + tableId).closest('.card');
        $(card).find('.spinner-container').remove();
        card.find('.card-body').removeClass('opaque');

      }

    });

    // Return success status
    return 'rendered'

  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId).keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    // Set actions for clicking on a row
    let tableRowClickAction = function(row) {

      // Get request url from table data attribute
      let url = row.closest('.table').attr('data-request-url');

      // Replace url formula id with data from row
      if (url.includes(":formula_id")) {
        let formulaId = row.attr('data-formula-id');
        url = url.replace(':formula_id', formulaId);
      } 

      // Replace url record id with data from row
      if (url.includes(":record_id")) {
        let recordId = row.attr('data-record-id');
        url = url.replace(':record_id', recordId);
      }

      // Replace url record type with data from row
      if (url.includes(":record_type")) {
        let recordType = row.attr('data-record-type');
        url = url.replace(':record_type', recordType);
      }

      // Open in new tab
      window.open(url);

    };

    // Set click areas
    let firstTd;
    firstTd = 'tbody > tr > td:first-child';
    $('#' + tableId).on('click', firstTd, function() { return tableRowClickAction($(this).closest('tr')); });


    const otherTds = 'tbody > tr > td:not(:first-child)';
    $('#' + tableId).on('click', otherTds, function() { return tableRowClickAction($(this).closest('tr')); });

    return 'setup';

  }

};